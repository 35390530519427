import React from "react";

const AutoDeduction = ({
	children,
	period,
	wallet,
	phoneNumber = "",
	className = "",
}) => {
	const mobileNumber = phoneNumber ? `(${phoneNumber})` : "";

	// FIXME Remote this return statement
	return null;

	return (
		<div
			className={`flex gap-4 items-center px-4 py-2 rounded-lg bg-gray-100 ${className}`}
		>
			<div className="flex flex-col gap-1">
				<p className="text-base font-bold">
					Do you want to enable auto-deductions?
				</p>
				<p>
					This will automatically deduct your {wallet} wallet {mobileNumber} by
					an amount equal to the{" "}
					<span className="font-bold">{period} plan</span> at the end of every{" "}
					{period} after this subscription lapses.
				</p>
			</div>

			{children}
		</div>
	);
};

export default AutoDeduction;
