import { ButtonLoader } from "components";
import PaddingX from "layouts/PaddingX";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import avatar from "assets/images/avatar.jpg";
import useAuthStore from "store/authStore";
import { FiCreditCard, FiLock, FiMail, FiUser } from "react-icons/fi";
import { BsBuilding } from "react-icons/bs";
import useLogout from "api/UserResource/useLogout";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import bg from "assets/profile_bg.jpg";
import { IoIosArrowBack } from "react-icons/io";
import { Role } from "utils/roles";
import useSearchParams from "hooks/useSearchParams";
import { getFlushErrorMessage } from "utils/errors";
import { IoCardOutline } from "react-icons/io5";

const ProfileLayout = ({ children, heading, isLoading, profile }) => {
	const { hasRoles } = useAuthStore();
	const { getParam } = useSearchParams();

	const flushErrorCode = getParam("flushError") || "";
	const flushErrorMessage = getFlushErrorMessage(flushErrorCode);

	const { mutateAsync, isLoading: isLoggingOut } = useLogout();

	const handleLogout = async () => {
		await mutateAsync();
	};

	const location = useLocation();

	// make icon size differ based on screen size
	const iconSize = 25;

	const links = [
		{
			label: "Profile",
			path: "/my-account",
			icon: <FiUser size={iconSize} />,
			qs: "My profile",
		},
		{
			label: "Emails",
			path: "/my-account/emails",
			icon: <FiMail size={iconSize} />,
			qs: "Emails",
		},
		{
			label: "Password & authentication",
			path: "/my-account/password",
			icon: <FiLock size={iconSize} />,
			qs: "Password and authentication",
		},
		{
			label: "Subscriptions",
			path: "/my-account/subscriptions",
			icon: <FiCreditCard size={iconSize} />,
			qs: "My subscription",
		},

		// FIXME Uncomment the comment
		// {
		// 	label: "Auto-deductions",
		// 	path: "/my-account/auto-deductions",
		// 	icon: <IoCardOutline size={iconSize} />,
		// 	qs: "Auto-deductions",
		// },
	];

	if (hasRoles([Role.SENIOR_PUBLISHER])) {
		links.push({
			label: "Publisher details",
			path: "/my-account/publisher",
			icon: <BsBuilding size={iconSize} />,
			qs: "Publisher details",
		});
	}

	if (hasRoles([Role.INSTITUTION])) {
		links.push({
			label: "Institution details",
			path: "/my-account/institution",
			icon: <BsBuilding size={iconSize} />,
			qs: "Institution details",
		});
	}

	const iconSizeMobile = 25;

	const mobileCss = `p-2 rounded-full h-12 w-12 flex items-center justify-center border border-akgreener `;

	const mobileLinks = [
		{
			label: "Profile",
			path: "/my-account",
			icon: (
				<span className={`${mobileCss}`}>
					<FiUser size={iconSizeMobile} />
				</span>
			),
			qs: "My profile",
		},
		{
			label: "Emails",
			path: "/my-account/emails",
			icon: (
				<span className={`${mobileCss}`}>
					<FiMail size={iconSizeMobile} />
				</span>
			),
			qs: "Emails",
		},
		{
			label: "Password",
			path: "/my-account/password",
			icon: (
				<span className={`${mobileCss}`}>
					<FiLock size={iconSizeMobile} />
				</span>
			),
			qs: "Password and authentication",
		},
		{
			label: "Subscription",
			path: "/my-account/subscriptions",
			icon: (
				<span className={`${mobileCss}`}>
					<FiCreditCard size={iconSizeMobile} />
				</span>
			),
			qs: "My subscription",
		},
	];

	if (hasRoles([Role.SENIOR_PUBLISHER])) {
		mobileLinks.push({
			label: "Publisher",
			path: "/my-account/publisher",
			icon: (
				<span className={`${mobileCss}`}>
					<BsBuilding size={iconSizeMobile} />
				</span>
			),
			qs: "Publisher details",
		});
	}

	if (hasRoles([Role.INSTITUTION])) {
		mobileLinks.push({
			label: "Institution",
			path: "/my-account/institution",
			icon: (
				<span className={`${mobileCss}`}>
					<BsBuilding size={iconSizeMobile} />
				</span>
			),
			qs: "Institution details",
		});
	}

	if (isLoading) {
		return (
			<PaddingX>
				<div className="min-h-screen flex justify-center items-center">
					<ButtonLoader size={60} />
				</div>
			</PaddingX>
		);
	}
	return (
		<div
			style={{ backgroundImage: `url(${bg})` }}
			className="text-sm font-thin font-sans text-akgreenest min-h-screen relative bg-white bg-cover bg-center bg-no-repeat bg-fixed  "
		>
			{flushErrorMessage && (
				<p className="bg-gray-100 py-2 px-4 flex justify-center items-center text-center border-b-2 border-akgreenest ">
					{flushErrorMessage}
				</p>
			)}
			<div
				className=" bg-white "
				style={{
					opacity: 0.96,
				}}
			>
				<PaddingX>
					<div
						className="flex justify-center gap-8 min-h-screen "
						style={{ opacity: 1 }}
					>
						<div className="py-10 hidden md:flex flex-col gap-4">
							<div className="flex items-center gap-2">
								<img
									src={profile?.avatar || avatar}
									alt="Profile"
									className="h-14 w-14 rounded-full "
								/>
								<div className="flex flex-col">
									<h3 className="font-bold">
										{profile?.first_name} {profile?.last_name}
									</h3>
									<span>{profile?.email}</span>
								</div>
							</div>

							<div className="space-y-1">
								{links.map((link, index) => (
									<NavLink
										exact
										key={index}
										to={link.path + (link.qs ? `?heading=${link.qs}` : "")}
										className="flex items-center gap-2 py-2 pl-2 pr-3 hover:bg-gray-200 rounded-md transition-all duration-200 ease-in-out border-l-4 border-transparent"
										activeClassName="bg-gray-200 border-l-4 border-akgreener"
									>
										{link.icon}
										<span>{link.label}</span>
									</NavLink>
								))}
							</div>

							<div className="flex-1 flex flex-col justify-end gap-2 pl-4">
								{/* <p className="text-blue-900 text-base font-bold">Quick Links</p> */}
								<div className="flex flex-col gap-1 text-xs">
									<NavLink
										to="/books"
										className="text-blue-900"
									>
										Browse books
									</NavLink>
									<NavLink
										to="/my-library"
										className="text-blue-900"
									>
										My Library
									</NavLink>
									<NavLink
										to="/subscriptions"
										className="text-blue-900"
									>
										Subscribe
									</NavLink>
									<hr />
									<NavLink
										to="/support"
										className="text-blue-900"
									>
										Support
									</NavLink>
									<NavLink
										to="/terms-and-conditions"
										className="text-blue-900"
									>
										Terms & conditions
									</NavLink>
									<NavLink
										to="/privacy-policy"
										className="text-blue-900"
									>
										Privacy policy
									</NavLink>
								</div>

								<button
									type="button"
									onClick={handleLogout}
									className="px-3 py-2 rounded-full max-w-max bg-akgreener text-white flex items-center gap-2"
								>
									Logout
									{isLoggingOut && (
										<AiOutlineLoading3Quarters
											size={20}
											className="animate-spin"
										/>
									)}
								</button>
							</div>
						</div>

						<div
							className="pt-14 pb-10 md:border-l border-gray-300 border-dashed md:pl-8 relative"
							style={{ width: 380 }}
						>
							<Link
								to="/books"
								className="absolute left-0 top-3  flex items-center justify-center md:hidden rounded-full p-2 bg-akgreenest text-white"
							>
								<IoIosArrowBack size={30} />
							</Link>
							<div className="flex items-center gap-4 mb-4 md:hidden pt-4">
								{mobileLinks?.map((link, index) => (
									<Link
										key={index}
										to={link.path}
										className={`rounded-full flex flex-col items-center gap-1 ${
											location.pathname === link.path ? "font-bold" : ""
										}`}
									>
										{link.icon}
										<span className="text-xs">{link.label}</span>
									</Link>
								))}
							</div>

							<h3 className="mb-4 pb-4 border-b border-gray-300">{heading}</h3>

							<div>{children}</div>
						</div>
					</div>
				</PaddingX>
			</div>
		</div>
	);
};

export default ProfileLayout;
